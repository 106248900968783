<template>
  <div>
    <page-title :title="`Performancebonds`">
      <template slot="button">
        <router-link
          :to="{ name: 'performancebonds.create' }"
          class="btn btn-secondary btn-sm float-right"
        >
          <i class="lni-plus mr-2"></i> Generate Performancebond
        </router-link>
      </template>
    </page-title>
    <table
      class="table  table-striped bg-white"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>PerformanceBond Number</th>
          <th>Company</th>
          <th>Counter Party</th>
          <th>Amount</th>
          <th>Effective Date</th>
          <th>Period</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(bond, i) in performancebonds" :key="`bidbond-${i}`">
          <td>{{ i + 1 }}</td>
          <td>{{ bond.performancebond_secret }}</td>
          <td>{{ bond.company_name }}</td>
          <td>{{ bond.counterparty_name }}</td>
          <td>{{ bond.tender_amount }}</td>
          <td>{{ bond.tender_effective_date }}</td>
          <td>{{ bond.tender_period }}</td>
          <td>
            <router-link
              :to="{
                name: 'performancebonds.show',
                params: { id: bond.performancebond_secret },
              }"
              class="btn btn-info btn-sm"
            >
              <i class="lni-eye mr-2"></i> view
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      performancebonds: [],
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.$axios
        .get("/api/v1/dashboard/performancebonds")
        .then((response) => {
          this.performancebonds = response.data;
          setTimeout(() => {
            this.$loader.stop();
            $('table').DataTable()
          }, 1000)
        })
        .catch((error) => {
          this.$loader.stop();
          this.$http(error.response);
        });
    },
  },
};
</script>
